import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useNavigate } from "react-router-dom";
import CountdownTimer from "../../../../components/UseCountDownTimer";
import AuthAPI from "../../../../apis/AuthAPI";
import IVerifyOTPRequest from "../../../../models/IVerifyOTPRequest";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SendVerificationModalSubmitForm } from "../../../../types/form-inputs/SendVerificationModalSubmitForm";
import "./SendVerificationCodeModal.css";
import * as environment from "../../../../config/environment";
export default function SendVerificationCodeModal({
  modalOpen,
  toggle,
  otpVerifyState,
}: any) {
  const nodeRef = React.useRef(null);
  const [dateTimeAfterFifteenMinutes, setDateTimeAfterFifteenMinutes] =
    useState(0);
  const [displayCounter, setDisplayCounter] = useState(false);
  const FIFTEEN_MINUTES_IN_MS = 15 * 60 * 1000;
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [inputDisabledChange, setInputDisabledChange] = useState();
  const [submitButtonDisabledInputLength, setSubmitButtonDisabledInputLength] =
    useState(true);
  const validationSchema = yup.object().shape({
    otp: yup.string().required("Doğrulama kodu gereklidir"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SendVerificationModalSubmitForm>({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();
  const onSubmit = (data: SendVerificationModalSubmitForm, event: any) => {
    event.preventDefault();
    setIsLoadingState(true);
    let verifyOTPRequest: IVerifyOTPRequest = {
      txnId: otpVerifyState.txnId,
      otp: data.otp,
      registryNumber: otpVerifyState.registryNumber,
      subscriberNumber: otpVerifyState.subscriberNumber,
    };
    AuthAPI.verifyOtp(verifyOTPRequest)
      .then((response: any) => {
        setIsLoadingState(false);
        navigate("/debt-query");
      })
      .catch((e: Error) => {
        setIsLoadingState(false);
      });
  };
  useEffect(() => {}, [toggle]);

  useEffect(() => {
    if (otpVerifyState && otpVerifyState.txnId) {
      let currentTime = new Date().getTime();
      setDateTimeAfterFifteenMinutes(currentTime + FIFTEEN_MINUTES_IN_MS);
      setDisplayCounter(true);
    }
  }, [otpVerifyState]);
  return (
    <>
      <Modal
        isOpen={modalOpen}
        toggle={toggle}
        className="modal-dialog-centered modal-lg"
        ref={nodeRef}
        backdrop="static"
      >
        <form onSubmit={handleSubmit(onSubmit)} className="p-3">
          <ModalHeader toggle={toggle}>Sms Doğrulama</ModalHeader>
          <ModalBody>
            <div className="form-group row mb-4">
              <div className="col-sm-3">
                <label className="mt-1">Sms Doğrulama Kodu</label>
              </div>
              <div className="col-sm-6">
                <input
                  type="text"
                  maxLength={6}
                  onInput={(e: any) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                    if (e.target.value.length === 6) {
                      setSubmitButtonDisabledInputLength(false);
                    }
                  }}
                  {...register("otp")}
                  className={`form-control ${errors.otp ? "is-invalid" : ""}`}
                  disabled={inputDisabledChange}
                />
                <div className="invalid-feedback">{errors.otp?.message}</div>
              </div>
            </div>
            <div>
              {displayCounter && (
                <CountdownTimer
                  targetDate={dateTimeAfterFifteenMinutes}
                  changeInputDisabled={(arg: any) => {
                    setInputDisabledChange(arg);
                  }}
                />
              )}
            </div>
            <div className="description-text-container">
              <p className="send-code-tel-text">
                Telefonunuza gönderilen doğrulama kodunu giriniz.
              </p>
              <p className="">
                <span>E-devlet üzerinden ödeme yapmak için </span>
                <a
                  href={environment.EDEVLET_PAYMENT_LINK}
                >
                  tıklayınız
                </a>
              </p>
              <p>
                Doğrulama kodu mesajı tarafınıza ulaşmadıysa e-devlet üzerinden
                sms doğrulamasına ihtiyaç duymadan kendi adınıza veya başkası
                adına hızlıca ödeme yapabilirsiniz.
              </p>
              <p className="">
                <span className="information-text">Bilgi: </span>
                <span>
                  Kurumumuzdan gönderilen smsler başarılı bir şekilde
                  iletilmesine rağmen bazı cihazlar filtreleme yaptığından
                  dolayı cihaz kaynaklı tarafınıza ulaşmayabilir
                </span>{" "}
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary me-2 mb-1 fw-bold"
              onClick={() => {
                toggle();
                reset();
              }}
              disabled={isLoadingState}
            >
              Vazgeç
            </button>
            <button
              className="btn btn-success mb-1 fw-bold"
              type="submit"
              disabled={
                isLoadingState ||
                submitButtonDisabledInputLength ||
                inputDisabledChange
              }
            >
              Borç Göster
              {isLoadingState && (
                <div
                  className="spinner-border text-light spinner-border-sm ms-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}
